import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";

function ContactPage() {
  return (
    <Layout>
      <SEO
        keywords={[
          `livestock`,
          `breeding`,
          `grazing`,
          `feedlot`,
          `lambs`,
          `cattle`,
          `agriculture`,
        ]}
        title="Contact"
      />
      <section>
        <div className="flex flex-wrap items-center justify-between max-w-4xl p-4 mx-auto md:p-8">
          <div>
            <h1 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
              Contact Us
            </h1>
            <div>
              Phone: 707-374-5585
            </div>
            <div>
              Email: <a href="mailto:office@emighlivestock.com">office@emighlivestock.com</a>
            </div>
            <div>
              Instagram: <a href="https://www.instagram.com/emighangusranch/">@emighangusranch</a>
            </div>
            <div>
              Address:<br />
              20 South Front St.<br />
              Rio Vista CA 94571
            </div>

          </div>
        </div>
      </section>
    </Layout>
  );
}

export default ContactPage;
